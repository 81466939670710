/* Estilos generales */
.stats-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;
}

.stat-item {
    flex: 1;
    min-width: 150px;
    text-align: center;
    margin: 10px;
}

.stat-item h1 {
    font-size: 2em;
    margin: 0;
}

.stat-item h3 {
    font-size: 1.2em;
    margin: 5px 0 0 0;
}

/* Estilos responsivos para pantallas pequeñas */
@media (max-width: 600px) {
    .stats-container {
        flex-direction: column;
    }

    .stat-item {
        width: 100%;
        margin: 10px 0;
    }
}
